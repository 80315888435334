import React from 'react';
import "./Footer.css";

const Footer = () => {

    let date = new Date();

  return (
    <footer className='footer bg-white flex flex-center colo' id = "footer">
        <div className='container flex flex-center w-100'>
            <div className='grid footer-content text-center'>
                <p className='text'>©2023 Swami Auto Spares. All rights reserved.</p>
                <span className='text'>&copy; {date.getFullYear().toString()} Design and developed by Applizr.</span>
            </div>
        </div>
    </footer>
  )
}

export default Footer