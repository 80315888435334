
import {FaHome, FaShoppingCart, FaPhoneAlt, FaEnvelopeOpen, FaMapMarkerAlt, FaTruckPickup} from "react-icons/fa";
import { GiMechanicGarage } from "react-icons/gi";


const gradient = "url(#blue-gradient)" ;

const services = [
    {
        id: 1,
        icon: <FaTruckPickup style = {{ fill: gradient }} />,
        title: "Pick Up and Drop Service",
        text: "Simply schedule a pickup for your two-wheeler, and our expert team will ensure it receives top-notch care at our garage before delivering it back to your doorstep, providing a seamless and time-saving solution for your vehicle maintenance needs"
    },
    {
        id: 2,
        icon: <FaHome style = {{ fill: gradient }} />,
        title: "At Home Service",
        text: "Enjoy the luxury of our At-Home Service – where our skilled technicians bring the expertise of our two-wheeler garage directly to your doorstep. Book a service, and experience the convenience of professional care and repairs for your motorcycle or scooter, all from the comfort of your home."
    },
    {
        id: 3,
        icon: <GiMechanicGarage style = {{ fill: gradient }} />,
        title: "In Garage Service",
        text: "Bring your two-wheeler to our state-of-the-art garage, where our dedicated team of skilled mechanics ensures thorough diagnostics, efficient repairs, and meticulous care, providing your motorcycle or scooter with the attention it deserves."
    },
    {
        id: 4, 
        icon: <FaShoppingCart style = {{ fill: gradient }} />,
        title: "Buy Spare Parts",
        text: "Ensure the longevity and optimal performance of your two-wheeler with our Genuine Spare Parts. Choose from our extensive range of authentic components, sourced directly from reputable manufacturers, to guarantee reliability and peak performance for your motorcycle or scooter."
    }
];

const contact = [
    {
        id: 25,
        icon: <FaPhoneAlt style = {{ fill: gradient }} />,
        info: "+91 8308442333 , +91 9004164009",
        redirect:"tel:+918308442333",
    },
    {
        id: 26,
        icon: <FaEnvelopeOpen style = {{ fill: gradient }} />,
        info: "swamienterprises109@gmail.com",
        redirect:"mailto:swamienterprises109@gmail.com",
    },
    {
        id: 27,
        icon: <FaMapMarkerAlt style = {{ fill: gradient }} />,
        info: "Survey No.2, 58, Sopan Baug Rd, near Guruchetnya girls hostel, Balewadi, Pune, Maharashtra 411045",
        redirect:"https://maps.app.goo.gl/1QhCnYvx6uttKUgcA",
    }
]

const sections = {services, contact};

export default sections;