import React, { useRef } from 'react';
import "./Contact.css";
import images from '../../constants/images';
import { Formik } from 'formik';
import Info from './Info';
import Map from './Map';
import emailjs from 'emailjs-com';

const Contact = () => {
 
  const form = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();
    try {
      const response = await emailjs.sendForm('service_aiq1wup', 'template_mjuy6wy', form.current, 'EbPosBkCvdxgIPQ7y');
      console.log('Email sent successfully:', response);
    } catch (error) {
      console.error('Email failed to send:', error);
    }
    e.target.reset();
  };

  const sendWhatsApp = () => {
    var phoneNumber = "+919004164009";
    var name = form.current.elements.user_name.value;
    var email = form.current.elements.user_email.value;
    var phone = form.current.elements.user_phone.value;
    var address = form.current.elements.user_address.value;
    var subject = form.current.elements.subject.value;

    var url = "https://wa.me/" + phoneNumber + "?text=" +
      "*Name:* " + name + "%0a" +
      "*Email:* " + email + "%0a" +
      "*Phone:* " + phone + "%0a" +
      "*Address:* " + address + "%0a" +
      "*Subject:* " + subject + "%0a%0a";

    window.open(url, '_blank').focus();
  }

  return (
    <section className='contact section-p-top bg-white' id = "contact">
        <div className='container'>
            <div className='contact-content grid text-center'>
                <div className='contact'>
                    <div className='section-t'>
                        <h3>Let's Talk?</h3> 
                        <p className='text'>You have any query about us, feel free to ask about it</p>
                    </div>

                   
                    <form ref={form} onSubmit={sendEmail}>
                                <div className='form-elem'>
                                    <h3>Name</h3>
                                    <input type = "text" name = "user_name" className = "form-control" />
                                    
                                </div>

                                <div className='form-elem'>
                                    <h3>Phone Number</h3>
                                    <input type = "text" name = "user_phone"  className = "form-control" />
                                   
                                </div>

                                <div className='form-elem'>
                                    <h3>E-mail</h3>
                                    <input type = "email" name = "user_email" className = "form-control" />
                                    
                                </div>

                                <div className='form-elem'>
                                    <h3>Address</h3>
                                    <input type = "text" name = "user_address" className = "form-control" />
                                   
                                </div>

                                <div className='form-elem'>
                                    <h3>Subject</h3>
                                    <textarea type = "text" name = "subject"  className = "form-control" />
                                    
                                </div>

                                <div className='flex flex-start'>
                                    {/* <button type = "submit" disabled = {isSubmitting} className = "submit-btn">contact us</button> */}
                                    <input type="submit" className = "submit-btn" value="Send" onClick={sendWhatsApp} />
                                </div>
                                <br/>
                                <br/>
                                <br/>
                                
                                
                            </form>
                       
                </div>
                <Map />

                <div className='contact-right'>
                    <img src = {images.form_main_img} alt = "" />
                </div>
            </div>
        </div>

        
        <Info />
    </section>
  )
}

export default Contact