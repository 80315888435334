import React from 'react'
import Navbar from '../Navbar/Navbar'
import "./Header.css"
import {FaPaperPlane} from "react-icons/fa";

const Header = () => {
  return (
    <header className='header flex flex-center flex-column'>
        <Navbar />
        <div className="container">
            <div className="header-content text-center flex flex-column">
                <h1 className="text-uppercase header-title">Swami Auto Spares & Services</h1>
                <p className="text-lead">Rev up your ride with our 2-wheeler haven—pickup and drop, At home service, genuine spare parts. We're your garage of choice for a smooth, worry-free journey on two wheels.</p>
                <a href="/" className="btn header-btn btn-blue">
                    Book Now !!
                </a>
            </div>
        </div>
    </header>
  )
}

export default Header
