import React from 'react';
import "./Contact.css";

const Map = () => {
  return (
    <div className='map map-content alignment'>
      {/* eslint-disable-next-line */}
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3780.227387008682!2d73.774737!3d18.5748576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b9766ba6a65b%3A0x2383cce005af4091!2sSwami%20Auto%20Spares%20%26%20Services!5e0!3m2!1sen!2sin!4v1638872644201!5m2!1sen!2sin" width="100%" height="450" style={{ border: 0 }} title="Google Map"></iframe>

      
    </div>
  );
}

export default Map;
