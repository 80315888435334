import React from 'react';
import "./About.css";
import images from '../../constants/images';

const About = () => {
  return (
    <section className='about section-p bg-white' id = "about">
        <div className='container'>
            <div className='about-content grid text-center'>
                    <div className='section-t'>
                        <h3>About Us</h3>
                    </div>
                    <p className = "text"><b>Welcome to Swami Auto Spares – Your Trusted Partner in Two-Wheeler Care!</b>

<p>At Swami Auto Spares, we believe in more than just repairing vehicles; we're dedicated to elevating your entire two-wheeler experience. Our recently opened shop boasts a team of highly experienced and passionate professionals who share a common goal – to alleviate the stress associated with your beloved two-wheelers.</p>

<p><b>Our Motto: Affordability and Trust, </b>

 We understand the challenges that come with owning and maintaining a two-wheeler. That's why our commitment revolves around providing services that are not only affordable but also built on a foundation of trust. Our motto is to ensure your peace of mind when it comes to the health and performance of your vehicles.</p>

<p><b>Experienced Team, Exceptional Service, </b>

Swami Auto Spares is more than just a repair shop; we're a community of skilled mechanics and dedicated staff who take pride in delivering top-notch service. With years of collective experience, our team is well-equipped to handle a wide range of maintenance and repair needs for all types of two-wheelers.</p>

Thank you for choosing Swami Auto Spares – Where Trust Meets Affordability!</p>
                </div>
            </div>
    </section>
  )
}

export default About